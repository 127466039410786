@font-face {
  font-family: "trirong";
  src: url("./Trirong-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "trirong";
  src: url("./Trirong-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "rubik";
  src: url("./Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "rubik";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "trirong";
}